<template>
  <div>
    <section id="wallets">
      <!-- <h1 style="padding: 0 20px">Wallets &amp; Goals</h1> -->
      <div
        v-if="Object.keys(user).length > 0"
        class="walletScroll recent_container"
        style="padding: 25px 20px; scroll-padding: 1.5rem; margin-top: -10px"
      >
        <TransferWallets
          v-for="(wallet, index) in user.accounts.filter(
            (item) => item.name == 'main' || item.name == 'close'
          )"
          :key="index"
          :wallet="wallet"
          @click.native.self="openSheetTansfer(wallet.id, wallet)"
          class="transferWallet walletScroll__item"
          style="margin-right: 10px"
          :accomplish="true"
          @emitOpenAchievementsModal="openAchievementsModal"
          @emitOpenTranferModal="openSheetTansfer(wallet.id, wallet)"
        />

        <template v-if="Object.keys(getGoals).length > 0">
          <AppGoalItemNew
            v-for="goal in childGoals"
            :key="goal.id"
            :goal="goal"
            :progress="progressRadius"
            @triggerGoalItem="openSheetTansfer(goal.account.id, goal)"
          />
        </template>

        <!-- <div
          class="goal-item walletScroll__item shadow"
          style="
            flex-direction: column;
            align-items: flex-start;
            padding: 15px;
            border-radius: 13px;
            margin-right: 10px;
          "
          v-for="goal in getGoals"
          :key="goal.id"
          @click="openSheetTansfer(goal.account.id, goal)"
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <div
              class="goal-img relative"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div class="relative mt-[-28px]">
                <svg
                  class="progress"
                  viewBox="0 0 168 116"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="progress__container"
                    d="M9 112C5.7435 103.285 4 93.8506 4 84C4 39.8172 39.8172 4 84 4C128.183 4 164 39.8172 164 84C164 93.8506 162.256 103.285 159 112"
                    stroke="#DBDBDB"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="progress__bar"
                    :data-radius="progressRadius"
                    stroke-dasharray="308 400"
                    :stroke-dashoffset="
                      progressOffset(
                        progressRadius,
                        (
                          (100 * formatPrice(goal.account.current_balance)) /
                          formatPrice(goal.product.price)
                        ).toFixed() / 100
                      )
                    "
                    d="M9 112C5.7435 103.285 4 93.8506 4 84C4 39.8172 39.8172 4 84 4C128.183 4 164 39.8172 164 84C164 93.8506 162.256 103.285 159 112"
                    stroke="#58C1E1"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <img
                  class="absolute top-[20px] bottom-0 mx-auto left-0 right-0"
                  style="width: 45px; height: 45px; object-fit: contain"
                  :src="resolveImage(goal.product.default_picture_link)"
                />
                <small
                  class="
                    absolute
                    bottom-[-25px]
                    left-0
                    right-0
                    w-[85%]
                    mx-auto
                    text-center
                    border-2 border-[#E9E9E9]
                    rounded-lg
                    py-[1px]
                    px-3
                    bg-white
                    font-bold
                  "
                  style="box-shadow: 0 5px 0 #e9e9e9; color: #58c1e1 !important"
                  >&pound;{{ formatPrice(goal.account.current_balance) }}</small
                >
              </div>
            </div>
            <div class="font-bold text-right text-sm w-[50%] leading-tight">
              <div
                style="
                  display: -webkit-box;
                  max-width: 200px;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                "
              >
                {{ goal.product.name }}
              </div>

              <div class="font-bold text-2xl">
                <small
                  style="
                    font-family: var(--fredoka) !important;
                    font-weight: 400;
                  "
                  >&pound;{{ formatPrice(goal.product.price) }}</small
                >
              </div>
            </div>
          </div>
        </div> -->

        <TransferWallets
          wallet="total"
          class="transferWallet walletScroll__item"
          style="margin-right: 10px"
          :accomplish="true"
        />

        <div
          class="goal-item walletScroll__item shadow flex-col p-[15px] rounded-[13px] mr-[10px] max-w-[200px] bg-[#0D4F74] text-white relative flex justify-start items-center"
        >
          <div>
            <p class="font-bold text-sm mb-1">Points Wallet</p>
            <div
              class="bg-white rounded-full min-w-[50px] py-[2px] text-center font-bold text-xl text-[#0D4F74]"
            >
              {{ Number(user.CurrentBalancePoints) }}
            </div>
          </div>
          <img
            class="absolute bottom-0 w-full"
            src="../../assets/images/stars.svg"
            alt="Stars"
          />
        </div>
      </div>
    </section>

    <!-- <section id="balance" class="shadow" style="margin: 0 20px">
      <div class="balance_text">
        <div class="balance_text__title">Your current</div>
        <div class="balance_text__title">Sonik Pocket Points</div>
      </div>
      <div class="balance_text__amount" style="color: white; font-size: 25px">
        {{ Number(user.CurrentBalancePoints) }}
      </div>
    </section> -->
    <section id="recent" style="padding: 0 20px">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <h1 >Today's Activity</h1>
      </div>

      <div
        v-if="
          getFilteredMiniMissionsGetter.pending_reward.length > 0 ||
          getFilteredMiniMissionsGetter.in_progress.length > 0 ||
          getFilteredMiniMissionsGetter.completed.length > 0 ||
          getFilteredMiniMissionsGetter.failed.length > 0 ||
          getFilteredMiniMissionsGetter.expired.length > 0
        "
      >
        <!-- <div
          v-if="
            takeMiniMissions.filter(
              (e) => e.date_times_mission.start_date === this.currentDayMethod
            ).length > 0
          "
          class="recent_container"
          :style="[
            takeMiniMissions.filter(
              (e) => e.date_times_mission.start_date === this.currentDayMethod
            ).length == 1
              ? { 'justify-content': 'center' }
              : {},
          ]"
        >
          <AppMission
            v-for="(mission, index) in takeMiniMissions.filter(
              (e) => e.date_times_mission.start_date === this.currentDayMethod
            )"
            :key="index"
            :title="mission.name"
            :missionId="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :statusText="getStatusText(mission)"
            :activeSumPoints="mission.sum_points.toString()"
            :type="mission.reward_type"
            :rewardVisible="mission.is_reward_visible"
            :wishlistProduct="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :startDate="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
          />
        </div> -->
        <div class="recent_container">

          <AppMission
            v-for="(
              mission, index
            ) in getFilteredMiniMissionsGetter.in_progress"
            :key="index"
            :title="mission.name"
            :missionId="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :statusText="getStatusText(mission)"
            :activeSumPoints="mission.sum_points.toString()"
            :type="mission.reward_type"
            :rewardVisible="mission.is_reward_visible"
            :wishlistProduct="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :startDate="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
          />

          <div style="background: #EBEBEB; height: 2px; width: 95%; display: block;margin: 20px auto 20px auto;"></div>

          <AppMission
            v-for="(
              mission, index
            ) in getFilteredMiniMissionsGetter.pending_reward"
            :key="index"
            :title="mission.name"
            :missionId="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :statusText="getStatusText(mission)"
            :activeSumPoints="mission.sum_points.toString()"
            :type="mission.reward_type"
            :rewardVisible="mission.is_reward_visible"
            :wishlistProduct="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :startDate="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
          />

          <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.completed"
            :key="index"
            :title="mission.name"
            :missionId="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :statusText="getStatusText(mission)"
            :activeSumPoints="mission.sum_points.toString()"
            :type="mission.reward_type"
            :rewardVisible="mission.is_reward_visible"
            :wishlistProduct="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :startDate="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
          />

          <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.failed"
            :key="index"
            :title="mission.name"
            :missionId="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :statusText="getStatusText(mission)"
            :activeSumPoints="mission.sum_points.toString()"
            :type="mission.reward_type"
            :rewardVisible="mission.is_reward_visible"
            :wishlistProduct="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :startDate="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
          />

          <AppMission
            v-for="(mission, index) in getFilteredMiniMissionsGetter.expired"
            :key="index"
            :title="mission.name"
            :missionId="mission.missionDateTimeId"
            :description="mission.description"
            :price="mission.sum_money.toString()"
            :statusText="getStatusText(mission)"
            :activeSumPoints="mission.sum_points.toString()"
            :type="mission.reward_type"
            :rewardVisible="mission.is_reward_visible"
            :wishlistProduct="mission.product"
            :timeleft="Number(mission.milisecondsDateTime)"
            :startDate="mission.date_times_mission.start_date"
            :status="mission.date_times_mission.status"
            @details="openSheetMissionDetails(mission)"
          />
        </div>
      </div>
      <div v-else class="recent_container">
        <!-- <div
          class="activity_item shadow"
          style="justify-content: center; align-items: center"
        > -->
          <div style="font-size: 0.8em; font-weight: 400;">
            No activities found
          </div>
        <!-- </div> -->
      </div>
    </section>

    <!-- <section id="family">
      <h1 style="padding: 0 20px">Family &amp; Friends</h1>
      <div
        v-if="getFamilyAndFriends.length > 0"
        class="walletScroll"
        style="padding: 25px 20px; scroll-padding: 1.5rem; margin-top: -10px"
      >
        <AppFamilyMember
          v-for="member in getFamilyAndFriends"
          :key="member.user.id"
          :memberId="member.user.id"
          :name="member.user.first_name"
          :imgsrc="member.user.avatarLink"
          :statusActive="member.relation_name"
          :children="member.childrenNumber"
          :familyMembers="member.memberNumber"
          :stars="member.average_stars"
          :birthday="member.birthday"
          :birthdayIn="member.remaining_day_births"
          :givenStars="member.myStar"
          class="walletScroll__item__2 shadow"
        />
      </div>
    </section> -->

    <!-- <AchievementsModal
      :is-open="achievementsModal"
      @close="closeAchievementsModal"
    /> -->

    <Sheet :show.sync="sheetTransfer" :on-close="closeSheetTansfer">
      <AppTransferFunds
        :id="transferId"
        :item="transferItem"
        :quick="true"
        :modalOpen="transferModal"
        :resetModal="isResetModal"
        @closeModal="closeTranferModal"
      />
    </Sheet>

    <Sheet
      :show.sync="sheetMissionDetails"
      :on-close="closeSheetMissionDetails"
    >
      <p style="text-align: center; font-size: 35px; font-weight: 700; color:#01A4C0 ;">{{ user.first_name }}</p>
      <!-- <div class="title">{{ user.first_name }}</div> -->
      <!-- {{missionObject}} -->
      <AppMissionDetails
        v-if="Object.keys(missionObject).length > 0"
        :title="missionObject.name"
        :missionDate="missionObject.date_times_mission.start_date"
        :missionStartHour="missionObject.date_times_mission.start_time"
        :missionEndHour="missionObject.date_times_mission.end_time"
        :description="missionObject.description"
        :price="missionObject.sum_money.toString()"
        :rewardType="missionObject.reward_type"
        :isRewardVisible="missionObject.is_reward_visible"
        :product="missionObject.product"
        :status="missionObject.date_times_mission.status"
      />
      <!-- <div v-if="Object.keys(missionObject).length > 0" class="flex">
        <button
          class="text-normal bg-black text-white max-w-[200px] py-3 mx-auto"
          v-if="missionObject.date_times_mission.status == 'in_progress'"
          @click="setMssionStatusAsDone(missionObject.missionDateTimeId)"
        >
          I did it
        </button>
      </div> -->

      <div
        v-if="Object.keys(missionObject).length > 0"
        class="flex justify-center"
      >
        <button
          v-if="missionObject.date_times_mission.status == 'in_progress'"
          @click="setMssionStatusAsDone(missionObject.missionDateTimeId)"
          class="bg-[#01A4C0] rounded-[100px] py-4 text-white text-xl font-bold mb-9"
          style="width: 95%;"
        >
          I did the mission
        </button>
      </div>
    </Sheet>
  </div>
</template>

<script>
  import config from "../../config";
  import AppMission from "../../components/AppMission";
  // import AppFamilyMember from "@/components/AppFamilyMember";
  import AppMissionDetails from "../../components/AppMissionDetails";
  import AppTransferFunds from "../../components/AppTransferFunds";
  // import AchievementsModal from "../../components/AppAchievementsModal";
  import TransferWallets from "../../components/TransferWallets";
  import Sheet from "../../components/Sheet";
  import AppGoalItemNew from "../../components/AppGoalItemNew";
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      AppMission,
      // AppFamilyMember,
      AppMissionDetails,
      AppTransferFunds,
      // AchievementsModal,
      TransferWallets,
      Sheet,
      AppGoalItemNew,
    },
    data() {
      return {
        progressRadius: 309,
        progressPercentage: 0,
        missionObject: [],
        sheetMissionDetails: false,
        sheetTransfer: false,
        countMissions: 0,
        missions: [],
        family_and_friends: [],
        wishlist: [],
        transferModal: false,
        gamesUrl: "",
        isResetModal: false,
        achievementsModal: false,
        transferId: null,
        transferItem: {},
        isLoading: true,
      };
    },
    methods: {
      ...mapActions([
        "fetchWishlistIndex",
        "fetchFamilyAndFriends",
        "setupUser",
        "fetchMissions",
        "fetchUser",
        "fetchGoals",
        "editMssionStatus",
        "TOAST",
        "setMissionDayParam"
      ]),
      setMssionStatusAsDone(id) {
        const currentDate = new Date().toISOString().split('T')[0].split('-').reverse().join('-');
        const payload = {
          date: currentDate,
          missionId: id
        };
        this.editMssionStatus(payload);
        this.sheetMissionDetails = false;
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      openSheetMissionDetails(item) {
        this.missionObject = item;
        this.sheetMissionDetails = true;
      },
      closeSheetMissionDetails() {
        this.sheetMissionDetails = false;
      },
      openSheetTansfer(id, item) {
        if (item.current_balance > 0 || item.account?.current_balance > 0) {
          this.transferId = id;
          this.transferItem = item;
          this.isResetModal = true;
          this.fetchGoals(false);
          this.sheetTransfer = true;
        } else {
          this.TOAST("No funds available!");
        }
      },
      closeSheetTansfer() {
        this.sheetTransfer = false;
        this.transferModal = false;
        this.isResetModal = false;
        this.transferId = null;
      },
      gamesRoute() {
        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        this.gamesUrl =
          config.GAMES_URL + "?user_id=" + userId + "&token=" + token;
      },
      openTranferModal(id, item) {
        this.transferId = id;
        this.transferItem = item;
        this.transferModal = true;
        this.isResetModal = true;
        this.fetchGoals();
      },
      openAchievementsModal() {
        this.TOAST("Coming soon!");
      },
      closeAchievementsModal() {
        this.achievementsModal = false;
        this.isResetModal = false;
      },
      closeTranferModal() {
        this.transferModal = false;
        this.isResetModal = false;
        this.transferId = null;
      },
      getStatusText(mission) {
        return mission.time_remaining_seconds;
      },
      getTimeleft(mission) {
        return mission.time_remaining_seconds;
      },
      progressOffset(radius, percentage) {
        return radius * (1 - percentage);
      },
    },
    computed: {
      ...mapGetters([
        "countMissionsIndex",
        "getSpendWalletBalance",
        "getSaveWalletBalance",
        "getFamilyAndFriends",
        "wishlistGetter",
        "takeMiniMissions",
        "getFilteredMiniMissionsGetter",
        "user",
        "getGoals",
        "getSecretStorePoints",
      ]),
      childGoals() {
        return this.getGoals
          .slice()
          .sort((a, b) => b.target_sum - a.target_sum)
          .slice(0, 3);
      },
      todayDate() {
        return new Date()
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-");
      },
      currentDayMethod() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        return [day, month, year].join("-");
      },
    },
    created() {
      let today = new Date();
      const dd = String(today.getDate());
      const mm = String(today.getMonth() + 1).padStart(1, '0'); //January is 0!
      const yyyy = today.getFullYear();

      today = dd + '-' + mm + '-' + yyyy;

      this.fetchMissions(today);
      this.fetchUser();
      this.fetchGoals();
      this.wishlist = this.$store.state.wishlistIndex;
      this.setMissionDayParam(today);
    },
  };
</script>

<style lang="scss" scoped>
  #recent {
    margin-top: 30px;

    .childPoints {
      background: #0b5074;
      width: 40px;
      height: 40px;
      max-width: 40px;
      max-height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: relative;
      font-size: 10.5px;
      font-weight: 600;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 5px;
      box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);

      &__amount {
        position: absolute;
        bottom: -4px;
        background: white;
        width: 34px;
        height: 14px;
        color: #0b5074;
        text-align: center;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        font-size: 10px;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 1.3em;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 15px;
    }

    .activity_item {
      width: 100%;
      height: 90px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      background: white;
      padding: 15px;
      border-radius: 15px;
      // border-bottom-right-radius: 15px;
      position: relative;
      overflow: hidden;
      transition: all 200ms ease-in-out !important;

      &:active {
        opacity: 0.6;
      }

      &__container {
        display: flex;
        align-items: center;
      }

      &__title {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 135px;
      }

      &__description {
        font-size: 11px;
        color: #9b9b9b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 185px;
      }

      &__amount {
        position: absolute;
        top: 10px;
        right: 15px;
        color: var(--main);
        font-weight: 600;
        font-size: 11px;
      }

      &__icon {
        margin-right: 15px;
        display: flex;
        align-items: center;

        img {
          width: 50px;
        }
      }
    }
  }

  #wallets {
    // margin-top: 30px;

    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 20px;
    }
  }

  .poundSign {
    font-size: 18px;
  }
  #family {
    margin-top: 10px;

    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 20px;
    }
  }

  .poundSign {
    font-size: 18px;
  }

  /************************************************************* OLD CODE */

  .dashboardHome {
    display: grid;
    grid-template-columns: 10fr 3fr;
    height: 100%;

    &__center {
      height: 100%;
      overflow-y: scroll;
      // padding: 0 20px;
      // display: flex

      &__missions {
        // background: #f8f5ed;
        // background: #f8f5ea;
        border-radius: 40px;
        height: auto;
        box-shadow: inset 0 0 5px 2px #f3f1f1;

        &__title {
          padding: 30px;

          font-size: 20px;
          line-height: 24px;
          color: #7a615a;
        }

        &__container {
          display: grid;
          grid-gap: 20px;
          padding: 0 16px 16px 16px;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          grid-auto-flow: column;
          grid-auto-columns: minmax(250px, 1fr);
          overflow-y: hidden;
          overflow-x: auto;

          &__info {
            display: grid;
            // grid-gap: 20px;
            grid-gap: 0;
            grid-template-rows: 0.88fr auto;
            /*margin-bottom: 20px;*/
          }
        }
      }
    }

    &__bottom {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      overflow-x: hidden;

      &__members {
        background: #f8f5ed;
        background: #f8f5ea;
        border-radius: 40px;
        height: auto;
        overflow-x: auto;
        box-shadow: inset 0 0 5px 2px #f3f1f1;

        &__title {
          padding: 30px;

          font-size: 20px;
          line-height: 24px;
          color: #7a615a;
        }

        &__container {
          display: grid;
          grid-gap: 50px;
          padding: 0 16px 16px 16px;
          grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
          grid-auto-flow: column;
          grid-auto-columns: minmax(1fr, 1fr);
          overflow-x: scroll;
        }
      }

      &__wishlist {
        background: #f8f5ea;
        // background: #f7f7f7;
        border-radius: 40px;
        height: auto;
        overflow-x: auto;
        box-shadow: inset 0 0 5px 2px #f3f1f1;

        &__title {
          padding: 30px;

          font-size: 20px;
          line-height: 24px;
          color: #7a615a;
        }

        &__container {
          display: grid;
          grid-gap: 60px;
          padding: 5px;
          margin-bottom: 10px;
          // grid-template-columns: repeat(1fr);
          grid-auto-flow: column;
          padding: 20px;
          // grid-auto-columns: minmax(1fr);
          // grid-auto-columns: 2fr;
        }
      }
    }

    &__sidebar {
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 20px;
      overflow-y: hidden;
      width: 100%;
      margin-left: 10px;
      // overflow-y: scroll;
    }
  }

  // .home-missions-subtitle {
  //   display: none;
  // }

  .mobile-wallet {
    display: none;
  }

  .home-show-count-missions {
    display: none;
  }

  .dashboardHome__bottom__wishlist {
    height: 220px !important;
  }

  .dashboardHome__bottom__members__container::-webkit-scrollbar {
    display: none;
  }

  .dashboardHome__bottom__wishlist__title {
    padding-bottom: 0;
  }

  .dashboardHome__bottom__wishlist::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 820px) {
    .dashboardHome {
      display: block;
      grid-template-columns: 1fr;

      // &__sidebar {
      //     // display: none;
      // }
      &__center {
        height: auto;
        overflow-y: hidden;
      }
    }
    .mission-col {
      margin-bottom: 17px !important;
    }
    .home-hide-count-missions {
      display: none;
    }
    .mobile-wallet {
      display: flex !important;
    }
    .desktop-wallet {
      display: none;
    }
    .home-show-count-missions {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.01) inset,
        0 2px 0 -1px rgba(0, 0, 0, 0.01), 0 3px 0 -1px rgba(0, 0, 0, 0.01),
        0 3px 13px -1px rgba(0, 0, 0, 0.21);
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.34) inset,
        0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.08),
        0 3px 13px -1px rgba(0, 0, 0, 0.05);
      margin: 0 15px;
      border-radius: 20px;
      .dashboardHome__center__missions__container__info {
        display: flex;
        width: 100%;
        padding: 20px;
        text-align: center;
        button {
          display: flex;
          text-align: center;
          align-items: center;
          font-size: 16px;
          justify-content: center;
          position: relative;
          padding: 0 !important;
          width: 100%;
          margin: 0;
          padding: 0;
          z-index: 99;
          background: #36d5f2;
          color: white;
          padding: 5px 25px;
          border-radius: 500px;
          font-weight: 900;
          font-size: 16px;
          box-sizing: border-box;
          background-color: #7fccde;
          color: white;
          padding: 5px 25px;
          display: inline-block;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom-color: rgba(0, 0, 0, 0.34);
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 1px 0 rgba(255, 255, 255, 0.34) inset,
            0 2px 0 -1px rgba(0, 0, 0, 0.13), 0 3px 0 -1px rgba(0, 0, 0, 0.08),
            0 3px 13px -1px rgba(0, 0, 0, 0.21);
          &:before {
            content: "";
            box-shadow: inset 0px 8px 48px;
            position: absolute;
            display: block;
            border-top: 2px solid #ddd;
            top: -8px;
            left: -8px;
            bottom: -8px;
            right: -8px;
            z-index: -1;
            border-radius: 500px;
          }
        }
      }
    }

    .home-missions-subtitle {
      display: block;
      font-size: 12px;
    }

    .dashboardHome__bottom__members__container::-webkit-scrollbar {
      display: none;
    }
    .dashboardHome__bottom__wishlist__container {
      display: flex;
      margin-bottom: 0;
      grid-gap: 0;
    }
    .dashboardHome__bottom__wishlist::-webkit-scrollbar {
      display: none;
    }
    .dashboardWishlistItem {
      border-radius: 20px;
      // margin-right: 20px;
      // width: 80%;
    }
    .dashboardWishlistItem__content {
      display: flex;
      padding: 5px 20px;
    }
    .mission-col {
      margin-bottom: 20px;
    }

    .appDashboardMissionsNumber {
      padding: 30px;
    }

    .sp_sidebar {
      padding: 15px;
    }

    .dashboardHome__bottom__members,
    .dashboardHome__bottom__wishlist {
      border-radius: 20px;
    }

    .sp_family {
      margin-bottom: 15px;
    }
    .appDashboardMissionsNumber {
      border-radius: 20px;
    }
    .dashboardHome__bottom {
      grid-template-columns: 1fr;
    }
    .dashboardHome__center__missions__container {
      grid-auto-flow: row;
    }
    .dashboardHome__sidebar {
      margin-top: 15px;
    }

    .dashboardHome__center__missions {
      padding: 20px;
      border-radius: 20px !important;
      // margin: 10px 10px 0 10px;
      margin: 0;
      padding-bottom: 0 !important;
    }

    .dashboardHome__center__missions__title {
      font-size: 20px !important;
      padding: 18px 0 22px 0;
    }
  }

  .dashboardHome__center__missions {
    padding: 17px;
  }

  .dashboard_under_missions {
    margin-top: 15px;
    padding: 0 15px;
  }

  .sp_sidebar {
    position: sticky;
    top: 0;
  }

  .sp_wishlist_no_items {
    text-align: center;
    padding-bottom: 40px;
    margin-top: 0;
    color: #7a615a;
  }

  @media screen and (min-width: 820px) {
    .dashboard_under_missions {
      padding: 0 !important;
    }
    .home-missions-subtitle {
      display: block;
      font-size: 16px;
      margin-top: 5px;
    }

    .dashboardHome__center__missions__title {
      font-size: 26px;
      padding-top: 10px;
      padding-bottom: 50px;
    }
  }

  @media screen and (max-width: 820px) {
    .appDashboardMissionsNumber:before {
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    .home-show-count-missions
      .dashboardHome__center__missions__container__info
      button:before {
      top: 0;
      left: 0;
    }

    .suffix {
      display: none !important;
    }

    #missionTabs ul {
      display: flex !important;
      justify-content: space-between !important;
    }

    .tabs-component-tabs {
      border: 0;
      align-items: center;
      display: flex;
      justify-content: space-between;
      background: #f8f5ed;
      margin-bottom: 20px;
      height: 50px;
    }

    .tabs-component {
      margin: 10px 0 0 0 !important;
      padding: 0 25px;
    }

    .missions__center__myMissions__title {
      display: block;
    }

    .tabs-component-tabs {
      // border: dashed 1px #ddd;
      border-radius: 6px;
    }

    .tabs-component-tab {
      color: #999;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      // height: 50px;
      list-style: none;
    }

    .tabs-component-tab:hover {
      color: #666;
    }

    .tabs-component-tab.is-active {
      background: #36d5f2;
      color: white !important;
      height: 50px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tabs-component-tab.is-disabled * {
      color: #cdcdcd;
      cursor: not-allowed !important;
    }

    .tabs-component-tab-a {
      align-items: center;
      color: inherit;
      display: flex !important;
      padding: 10px 0 !important;
      margin: 0 !important;
      font-size: 10px;
      text-decoration: none;
    }

    .tabs-component-panels {
      padding: 0;
    }
    .appGoalsProgressBar__total,
    #wishlist_progress,
    .appGoalsProgressBar,
    .appGoalsProgressBar__total__completed {
      border-radius: 20px !important;
    }
    .storeItem__header {
      width: 75% !important;
    }
    .storeItem__header .appGoalsProgressBar {
      width: 100% !important;
      border-radius: 20px !important;
      margin: 0 !important;
    }

    .storeItem__header__trash {
      right: 0 !important;
      left: unset !important;
      top: 0 !important;
    }
  }
  .placeholderCard {
    height: 360px;
    width: 100%;
    background: #f1eee1;
    border-radius: 40px;
  }
  .journeyCard {
    display: block;
    align-items: center;
    justify-content: center;
  }

  #balance {
    background: var(--main);
    background: rgb(54, 213, 242);
    background: linear-gradient(
      180deg,
      rgba(54, 213, 242, 1) 0%,
      rgba(0, 204, 255, 1) 100%
    );
    // width: 100%;
    height: 90px;
    border-radius: 20px;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .balance_text {
      color: white;

      &__title {
        font-weight: bold;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.03rem;
      }

      &__amount {
        font-size: 20px;
        font-weight: bold;
        font-weight: 700;
        letter-spacing: 0.02rem;
      }
    }

    .balance_button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      &:active {
        opacity: 0.8;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
</style>
