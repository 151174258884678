var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goal-item goal-item-extra walletScroll__item shadow",on:{"click":function($event){return _vm.$emit('triggerGoalItem')}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","width":"100%"}},[_c('div',{staticClass:"goal-img relative",staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('div',{staticClass:"relative mt-[-28px]"},[_c('svg',{staticClass:"progress",attrs:{"viewBox":"0 0 168 116","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"progress__container",attrs:{"d":"M9 112C5.7435 103.285 4 93.8506 4 84C4 39.8172 39.8172 4 84 4C128.183 4 164 39.8172 164 84C164 93.8506 162.256 103.285 159 112","stroke":"#DBDBDB","stroke-width":"5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{staticClass:"progress__bar",attrs:{"data-radius":_vm.progress,"stroke-dasharray":"308 400","stroke-dashoffset":_vm.progressOffset(
                _vm.progress,
                (
                  (100 * _vm.formatPrice(_vm.goal.account.current_balance)) /
                  _vm.formatPrice(_vm.goal.product.price)
                ).toFixed() / 100
              ),"d":"M9 112C5.7435 103.285 4 93.8506 4 84C4 39.8172 39.8172 4 84 4C128.183 4 164 39.8172 164 84C164 93.8506 162.256 103.285 159 112","stroke":"#58C1E1","stroke-width":"5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('img',{staticClass:"absolute top-[20px] bottom-0 mx-auto left-0 right-0",staticStyle:{"width":"45px","height":"45px","object-fit":"contain"},attrs:{"src":_vm.resolveImage(_vm.goal.product.default_picture_link)}}),_c('small',{staticClass:"absolute bottom-[-25px] left-0 right-0 w-[85%] mx-auto text-center border-2 border-[#E9E9E9] rounded-lg py-[1px] px-3 bg-white font-bold",staticStyle:{"box-shadow":"0 5px 0 #e9e9e9","color":"#58c1e1 !important"}},[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.goal.account.current_balance)))])])]),_c('div',{staticClass:"font-bold text-right text-sm w-[50%] leading-tight"},[_c('div',{staticStyle:{"display":"-webkit-box","max-width":"200px","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.goal.product.name)+" ")]),_c('div',{staticClass:"font-bold text-2xl"},[_c('small',{staticStyle:{"font-family":"var(--fredoka) !important","font-weight":"400"}},[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.goal.product.price)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }