<template>
  <div class="appTransferFunds">
    <div v-if="currentstep == 1" class="addMissionExplChild">
      <h1 class="transferInfoTitle">Transfer money from:</h1>

      <section v-if="user.accounts" class="wallets" style="margin-bottom: 20px">
        <div
          class="walletScroll recent_container"
          id="walletsScroll"
          style="padding: 25px 20px; scroll-padding: 1.5rem; margin-top: -20px"
        >
          <TransferWallets
            v-if="item.name == 'close' || item.name == 'main'"
            :wallet="item"
            @click.native="selectTransfer(item.id, item)"
            :style="[
              selectedTransferAccount == item.id
                ? { background: '#ff4f79', color: 'white' }
                : { background: '#AAAAAA', filter: 'grayscale(100%)' },
            ]"
            class="transferWallet walletScroll__item"
            style="margin-right: 10px"
          />
          <template v-else-if="item.account">
            <AppGoalItemNew
              :goal="item"
              :progress="progressRadius"
              @triggerGoalItem="selectTransfer(item.account.id, item)"
              :style="[
                selectedTransferAccount == item.account.id
                  ? { background: '#ff4f79', color: 'white' }
                  : { background: '#AAAAAA', filter: 'grayscale(100%)' },
              ]"
            />
          </template>

          <TransferWallets
            v-for="(wallet, index) in user.accounts.filter(
              (item) => item.name == 'main' || item.name == 'close'
            )"
            :key="index"
            :wallet="wallet"
            @click.native="selectTransfer(wallet.id, wallet)"
            :style="[
              selectedTransferAccount == wallet.id
                ? { background: '#ff4f79', color: 'white' }
                : { background: '#AAAAAA', filter: 'grayscale(100%)' },
            ]"
            class="transferWallet walletScroll__item"
            style="margin-right: 10px"
            :class="quick && wallet.id === id ? 'hideQuick' : ''"
          />

          <AppGoalItemNew
            v-for="goal in getGoals"
            :key="goal.id"
            :goal="goal"
            :progress="progressRadius"
            @triggerGoalItem="selectTransfer(goal.account.id, goal)"
            :style="[
              selectedTransferAccount == goal.account.id
                ? { background: '#ff4f79', color: 'white' }
                : { background: '#AAAAAA', filter: 'grayscale(100%)' },
            ]"
            :class="quick && goal.account.id === id ? 'hideQuick' : ''"
          />
        </div>
      </section>
    </div>

    <div v-if="currentstep == 2" class="addMissionExplChild">
      <h1 class="transferInfoTitle">Transfer money to:</h1>

      <div v-if="user.accounts" style="margin-bottom: 20px">
        <div
          class="walletScroll recent_container"
          style="padding: 25px 20px; scroll-padding: 1.5rem; margin-top: -20px"
        >
          <TransferWallets
            v-for="(wallet, index) in user.accounts.filter(
              (item) => item.name == 'main' || item.name == 'close'
            )"
            :key="index"
            :wallet="wallet"
            @click.native="
              selectedTransferAccount == wallet.id
                ? null
                : selectTransferTo(wallet.id, wallet, 'wallet')
            "
            :style="[
              selectedTransferToAccount == wallet.id
                ? { background: '#ff4f79', color: 'white' }
                : { background: '#AAAAAA', filter: 'grayscale(100%)' },
            ]"
            class="transferWallet walletScroll__item"
            style="margin-right: 10px"
            :class="{ disabledOption: selectedTransferAccount == wallet.id }"
          />

          <AppGoalItemNew
            v-for="goal in getGoals"
            :key="goal.id"
            :goal="goal"
            :progress="progressRadius"
            @triggerGoalItem="
              selectedTransferAccount == goal.account.id
                ? null
                : selectTransferTo(goal.account.id, goal, 'goal')
            "
            :style="[
              selectedTransferToAccount == goal.account.id
                ? { background: '#ff4f79', color: 'white' }
                : { background: '#AAAAAA', filter: 'grayscale(100%)' },
            ]"
            :class="{
              disabledOption: selectedTransferAccount == goal.account.id,
            }"
          />
        </div>
      </div>
    </div>

    <div v-if="currentstep == 3" class="addMissionExplChild">
      <h3 class="transferInfoMoney">How much would you like to transfer?</h3>
      <div
        v-if="
          getSpendWalletBalance == 0 &&
          getSaveWalletBalance == 0 &&
          getGoals.filter((item) => item.account.current_balance > 0).length ==
            0
        "
        class="noFundsError"
      >
        You do not have enough funds to transfer!
      </div>
      <div v-else>
        <div class="amountToTransfer">
          <div style="font-size: 12px">
            You have
            <span>&pound;{{ formatPrice(transferAll) }}</span> available for
            transfer
          </div>
          <div
            v-if="
              selectedTransferTo == 'goal' &&
              (getSpendWalletBalance >= finishTransferTo.target_sum ||
                getSaveWalletBalance >= finishTransferTo.target_sum)
            "
            class="transferAllAction"
            @click="transferAllSum(finishTransferTo.target_sum)"
          >
            I want to transfer &pound;{{ finishTransferTo.target_sum }}
          </div>
          <div v-else class="transferAllAction" @click="transferAllSum(transferAll)">
            I want to transfer all
          </div>
        </div>
        <AppErrorMessage :message="morefundsNeeded" />
        <input
          class="guardian-login-form__input"
          placeholder="0.00"
          type="email"
          spellcheck="false"
          v-model="transferObj.sum"
          style="width: 100%; margin-bottom: 15px"
        />
        <AppErrorMessage :message="error" style="display: none" />

        <AppButton
          text="Transfer"
          :color="validateFunds"
          :mini="true"
          @click="transfer"
          style="margin-top: 20px"
          class="rounded py-3 px-12 text-white text-sm font-bold"
        />
      </div>
    </div>

    <div v-if="currentstep == 4" class="addMissionExplChild">
      <h1 class="transferInfoTitleSuccess mt-5">Success!</h1>
      <p class="successDesc">
        You've transferred
        <span class="redSuccess"
          >&pound;{{ formatPrice(transferObj.sum) }}</span
        >
        from
        <span class="redSuccess" v-if="finishTransferFrom.name == 'close'"
          >Save Wallet</span
        >
        <span class="redSuccess" v-else-if="finishTransferFrom.name == 'main'"
          >Spend Wallet</span
        >
        <span class="redSuccess" v-else>{{
          finishTransferFrom.product.name.substring(0, 8) + ".."
        }}</span>
        to
        <span class="redSuccess" v-if="finishTransferTo.name == 'close'"
          >Save Wallet</span
        >
        <span class="redSuccess" v-else-if="finishTransferTo.name == 'main'"
          >Spend Wallet</span
        >
        <span class="redSuccess" v-else>{{
          finishTransferTo.product.name.substring(0, 8) + ".."
        }}</span>
      </p>
    </div>

    <div v-if="currentstep <= 2">
      <StepControls
        v-for="(step, index) in steps"
        :step="step"
        :key="index"
        :calendar="checkPassStep"
        :stepcount="steps.length"
        :currentstep="currentstep"
        @step-change="stepChanged"
        @step-submit="selectTransfer"
      >
      </StepControls>
    </div>
  </div>
</template>

<script>
  // import AppDashboardWallet from "@/components/AppDashboardWallet";
  import TransferWallets from "../components/TransferWallets";
  // import StepNavigation from "../components/wizard/StepNavigation";
  import StepControls from "../components/wizard/StepControls";
  import "../assets/css/vue-form-wizard.css";
  import AppButton from "../components/AppButton";
  // import AppInput from "@/components/AppInput";
  import AppErrorMessage from "../components/AppErrorMessage";
  import AppGoalItemNew from "../components/AppGoalItemNew";

  import { mapActions, mapGetters } from "vuex";
  import config from "../config";
  import status from "../utils/statusCode";

  export default {
    components: {
      // AppDashboardWallet,
      TransferWallets,
      // StepNavigation,
      StepControls,
      AppButton,
      // AppInput,
      AppErrorMessage,
      AppGoalItemNew,
    },
    props: {
      goals: {
        type: Array,
        default: () => [],
      },
      resetModal: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
      item: {
        type: Object,
        default: () => {},
      },
      quick: {
        type: Boolean,
      },
      modalOpen: {
        type: Boolean,
      },
    },
    data() {
      return {
        progressRadius: 309,
        passStep: false,
        transferAll: null,
        sum: null,
        isActive: "",
        arrowPosition: "",
        error: "",
        selectedTransferAccount: null,
        selectedTransferToAccount: null,
        selectedTransferTo: null,
        transferObj: {
          from: null,
          to: null,
          sum: null,
        },
        finishTransferFrom: [],
        finishTransferTo: [],
        currentstep: 1,
        indicatorclass: true,
        step: 1,
        active: 1,
        firststep: true,
        nextStep: "",
        lastStep: "",
        laststep: false,
        steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-folder-open",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
          {
            id: 4,
            title: "STEP 4",
            icon_class: "fa fa-paper-plane",
          },
        ],
      };
    },
    computed: {
      ...mapGetters([
        "getSpendWalletBalance",
        "getSaveWalletBalance",
        "user",
        "getGoals",
      ]),
      checkPassStep() {
        return this.passStep;
      },
      goalsAndWallets() {
        var walletsArr = this.user.accounts.filter(
          (item) => item.name == "main" || item.name == "close"
        );
        return [...walletsArr, ...this.getGoals];
      },
      validateFunds() {
        // this.error = "";
        if (
          this.transferObj.sum > 0 &&
          this.transferObj.sum <= this.transferAll
        ) {
          // this.isActive = "blue";
          return "blue";
        } else {
          // this.isActive = "";
          return "";
        }
        // var leftBallance = this.getSpendWalletBalance;
        // var rigntBallance = this.getSaveWalletBalance;

        // if (leftBallance == 0 && rigntBallance == 0) {
        //   this.error = "The savings and spending wallet has no funds";
        // }
      },
      morefundsNeeded() {
        if (this.transferObj.sum > this.transferAll) {
          return "You do not have enough funds available!";
        } else {
          return "";
        }
      },
      // checkGoalsAccount() {
      //   if (this.getGoals.length > 0) {
      //     this.getGoals.forEach(item => {
      //       if (item.account.current_balance > 0) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     })
      //   } else {
      //     return false;
      //   }
      // }
    },
    mounted() {
      // console.log(this.user);

      if (this.resetModal) {
        this.currentstep = 1;
      }

      setTimeout(() => {
        if (this.arrowPosition == "") {
          var leftBallance = this.getSpendWalletBalance;
          var rigntBallance = this.getSaveWalletBalance;

          if (leftBallance == 0 && rigntBallance == 0) {
            this.error = "The savings and spending wallet has no funds";
          } else {
            if (leftBallance > 0) {
              this.arrowPosition = "right";
            } else if (rigntBallance > 0) {
              this.arrowPosition = "left";
            } else {
              this.arrowPosition = "";
            }
          }
        }
      }, 3000);
    },
    methods: {
      ...mapActions([
        "transferFromMainToClose",
        "transferFromCloseToMain",
        "fetchUser",
        "TOAST",
      ]),
      progressOffset(radius, percentage) {
        return radius * (1 - percentage);
      },
      transferAllSum(amount) {
        this.transferObj.sum = amount;

        // console.log("this.transferAll: ", this.transferAll);
        // console.log("this.transferObj.sum: ", this.transferObj.sum);
        // console.log("getGoals", this.getGoals);
        // console.log("selectedTransferAccount", this.selectedTransferAccount);
        // console.log("amount", amount);
      },
      stepChanged: function (step) {
        // this.currentstep = step;
        if (step == 1) {
          this.passStep = false;
          this.currentstep = step;
        } else {
          this.passStep = true;
        }

        this.currentstep = step;

        if (step == 2) {
          this.currentstep = step;
          this.passStep = false;
        }
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      selectTransfer(id, item) {
        if (item.current_balance > 0 || item.account?.current_balance > 0) {
          this.selectedTransferAccount = id;
          this.finishTransferFrom = item;
          // console.log("transfer from id");
          // console.log(id);
          // console.log(item);
          this.transferObj.from = id;
          this.passStep = false;
          this.passStep = true;

          if (item.account != undefined) {
            // console.log("este");
            this.transferAll = item.account.current_balance;
          } else {
            this.transferAll = item.current_balance;
          }
        }

        // console.log(this.transferAll);
      },
      selectTransferTo(id, item, type) {
        this.selectedTransferToAccount = id;
        this.selectedTransferTo = type;
        this.finishTransferTo = item;
        this.transferObj.to = id;
        this.passStep = true;
        // console.log("transfer to id");
        console.log("item", item);
      },
      switchTransaction(position) {
        if (position == "right") {
          if (this.getSpendWalletBalance > 0) {
            this.arrowPosition = position;
          } else {
            this.error = "The spending wallet has no funds";
          }
        }
        if (position == "left") {
          if (this.getSaveWalletBalance > 0) {
            this.arrowPosition = position;
          } else {
            this.error = "The savings wallet has no funds";
          }
        }
      },
      transferMoney({ commit, dispatch }, details) {
        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        this.axios
          .post("/operations/transferMoneyBetweenAccounts", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            account_from_id: details.from,
            account_to_id: details.to,
            sum: details.sum,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              // console.log(response.data);
              commit("TRANSFER_MONEY", details);
              // location.reload();
              dispatch("fetchUser", null);
              // dispatch("fetchProfile", null);
              // dispatch("fetchGoals");
            } else if (status.invalidToken(statusCode)) {
              this.deleteStorage();
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
            throw error;
          });
      },
      transfer() {
        // console.log("account id");
        // console.log(this.user.accounts);

        // console.log("object transfer");
        // console.log(this.transferObj);
        // console.log("object transfer");

        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        this.axios
          .post("/operations/transferMoneyBetweenAccounts", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            account_from_id: this.transferObj.from,
            account_to_id: this.transferObj.to,
            sum: this.transferObj.sum,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              // console.log(response.data);
              // this.$store.commit("TRANSFER_MONEY", this.transferObj);
              // location.reload();
              this.$store.dispatch("fetchUser", null);
              this.currentstep = 4;
              // dispatch("fetchProfile", null);
              this.$store.dispatch("fetchGoals", false);
            } else if (status.invalidToken(statusCode)) {
              this.deleteStorage();
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
            throw error;
          });
      },
    },
    watch: {
      resetModal() {
        if (this.resetModal) {
          this.currentstep = 1;
          // console.log("this reset modal");
          // console.log(this.resetModal);
          // console.log("this reset modal");

          this.selectedTransferAccount = null;
          this.selectedTransferToAccount = null;
          this.transferObj.from = null;
          this.transferObj.to = null;
          this.transferObj.sum = null;
          this.isActive = "";
          this.passStep = false;
          // this.id = null;
        }
      },
      id() {
        if (this.id != null) {
          this.selectTransfer(this.id, this.item);
          // console.log("--------------------");
          // console.log(555);
          // console.log("this id: " + this.id);
          // console.log(this.item);
          // console.log("---------------------");
        }
      },
      modalOpen() {
        if (this.modalOpen) {
          document.getElementById("walletsScroll").scrollLeft = 0;
        }
      },
      // resetModal: function (newVal, oldVal) { // watch it
      //   console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      // }
    },
  };
</script>

<style lang="scss" scoped>
  .wallets {
    margin-top: 30px;

    h1 {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02rem;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    }

    .recent_container {
      margin-top: 20px;
    }
  }

  .poundSign {
    font-size: 18px;
  }

  .cursor {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .appTransferFunds {
    width: auto;

    &__wallets {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      height: 100%;
    }

    &__name {
      text-align: center;
      padding-top: 10px;
      font-size: 15px;
      line-height: 15px;
      color: #7a615a;
    }
  }
  .arrow {
    height: 70px;
    width: 70px;
    filter: grayscale(1);
    border-radius: 50%;
    opacity: 0.8;
  }
  .transferInfoMoney {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin: 0;
    color: #7a615a;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .transferInfoTitle {
    width: 100%;
    text-align: center;
    color: #7a615a;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 18px;
  }

  // .goal-item {
  //   // background: linear-gradient(90deg, #bc4e9c 0%, #f80759 100%);
  //   background: white;
  //   // display: grid;
  //   grid-template-columns: 1fr 3fr;
  //   padding: 5px;
  //   border-radius: 15px;
  //   align-items: center;
  // }

  // .goal-img {
  //   background: white;
  //   border-radius: 15px;
  //   overflow: hidden;
  //   margin-right: 15px;
  //   width: 80px;
  //   height: 80px;
  //   padding: 5px;

  //   img {
  //     object-fit: contain;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  // .goal-description h1 {
  //   font-size: 16px;
  //   display: -webkit-box;
  //   height: 32px;
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   // color: white;
  // }

  // .goal-description p {
  //   font-size: 20px;
  //   // color: white;

  //   font-weight: 100;
  // }

  .step-wrapper {
    display: none;
  }
  .step-wrapper.activeStep {
    display: block;
  }

  .step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 0;
  }
  .step-indicator li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
  }
  .step-indicator li:after {
    background-color: #f5f5f5;
    content: "";
    display: block;
    height: 8px;
    position: absolute;
    width: 100%;
    top: 12px;
  }
  .step-indicator li:after {
    left: 50%;
  }
  .step-indicator li:last-child:after {
    display: none;
  }
  .step-indicator li.activeStep .step {
    border-color: #967f79;
    background: #967f79;
    color: #967f79;
    box-shadow: 0 0 0 4px #ffffff, 0 0 0 4px #ffffff inset;
  }
  .step-indicator li.activeStep .caption {
    color: #967f79;

    font-weight: 100;
    font-size: 16px;
  }
  .step-indicator li.complete:after {
    background-color: #36d5f2;
  }
  .step-indicator li.complete .step {
    border-color: #36d5f2;
    color: #36d5f2;
    background: #36d5f2;
  }
  .step-indicator li.complete .caption {
    color: #36d5f2;

    font-weight: 100;
    font-size: 16px;
  }
  .step-indicator .step {
    background-color: #f5f5f5;
    border-radius: 50%;
    border: 2px solid #f5f5f5;
    color: #ccc;
    font-size: 24px;
    height: 30px;
    line-height: 0px;
    margin: 0 auto;
    position: relative;
    width: 30px;
    z-index: 1;
    box-shadow: 0 0 0 4px #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms;
  }
  .step-indicator .step:hover {
    cursor: pointer;
  }
  .step-indicator .caption {
    color: #ccc;
    padding: 11px 16px;

    font-weight: 100;
    font-size: 16px;
  }
  .tofrom {
    background: #ff4f79;
    padding: 2px 10px;
    border-radius: 5px;
    color: white;
    width: 120px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
  }
  .transferSuccess {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    grid-gap: 20px;
    align-items: center;
    width: 100%;
  }
  .successDesc {
    text-align: center;
    color: #7a615a;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .transferInfoTitleSuccess {
    width: 100%;
    text-align: center;
    color: var(--main);
    font-weight: 700;
    margin: -40px 0 10px 0;
    font-size: 30px;
  }
  .redSuccess {
    color: var(--main);
  }
  .disabledOption {
    opacity: 0.3;
    display: none !important;
  }
  .noFundsError {
    text-align: center;
    margin: 10px auto 0 auto;
    color: white;
    background: #ff4779;
    display: table;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .amountToTransfer {
    // text-align: center;
    color: #7a615a;
    text-align: center;
    border-top: 1px solid #eee;
    padding: 15px 30px;
    width: auto;
    display: table;
    margin: 15px auto 0 auto;
    align-items: center;
    flex-direction: column;

    span {
      color: #36d5f2;
      font-weight: bold;
    }

    .transferAllAction {
      // background: #36d5f2;
      color: var(--main);
      padding: 5px 0;
      // border-radius: 100px;
      font-size: 16px;
      width: auto;
      display: inline-block;
      margin-top: 15px;
      cursor: pointer;
      font-weight: bold;
      border-bottom: 1px dashed var(--main);
      // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      //   0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 10px rgba(black, 0.1) inset;
    }
  }

  .guardian-login-form {
    height: 500px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__input {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      padding: 15px 20px;
      border: 1px solid #dedede;
      border-radius: 5px;
      outline: 0;
      height: 50px;
      color: #7a615a;
      font-size: 14px;
      background: #fafafa;
      -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
      -webkit-appearance: none;
    }
  }
</style>
